.text-404 {
  color: #ffffff;
}

.text-404 h1 {
  font-size: 180px;
  font-weight: bold;
} /*# sourceMappingURL=NotFound.css.map */

.spinner {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #f28c00; /* Blue */
  border-radius: 80%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
